<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 20px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
              size="mini"
            >
              <el-form-item label="环保垃圾屋名称：" style="margin-bottom: 0px">
                {{form.ecoName}}
              </el-form-item>
              <el-form-item label="所属标段：">
                {{form.bidName}}
              </el-form-item>
              <el-form-item label="类型：">
                <el-select v-model="form.ecoType" placeholder="未选择" disabled>
                  <el-option
                    v-for="(item, idx) in $enums_hw.ecoType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="用途：">
                <el-select v-model="form.application" placeholder="未选择" disabled>
                  <el-option
                    v-for="(item, idx) in $enums_hw.application.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="管理配员(名)：">
                {{form.demandWorker}}
              </el-form-item>
              <el-form-item label="垃圾压缩箱数量(个)：">
                {{form.garbageCompactorNum}}
              </el-form-item>
              <el-form-item label="交付时间：" style="width: 100%">
                <el-date-picker
                  v-model="form.deliveryTime"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                  disabled
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="产权所有：">
                <el-select v-model="form.propertyRights" placeholder="未选择" disabled>
                  <el-option
                    v-for="(item, idx) in $enums_hw.propertyRights.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="是否通水：">
                <el-checkbox v-model="form.waterReady" disabled>是</el-checkbox>
              </el-form-item>
              <el-form-item label="是否通电：">
                <el-checkbox v-model="form.electricityReady" disabled>是</el-checkbox>
              </el-form-item>
              <el-form-item label="排污情况：" style="width: 100%">
                {{form.sewageSituation}}
              </el-form-item>
              <el-form-item label="地址：" style="width: 100%">
                {{form.ecoAddress}}
              </el-form-item>
              <el-form-item label="环保垃圾屋附件：" style="width: 100%">
                <ayl-image v-model="form.ecoImageUrls"></ayl-image>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- {{mapData}} -->
        <ayl-map v-model="mapData" icon="ljhbw" :enableDraw="false"></ayl-map>
      </div>

      <div class="tac mt40px">
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import edit from "@/mixins/edit";
export default {
  mixins: [edit],

  data() {
    return {
      debug: 0,
      nav: [{ name: "环保垃圾屋详情" }],
      submitLoading: false,
      uploadData: {
        fileKey: null
      },
      files: [],
      mapData: {
        //地图数据
        lnglat: null, //经纬度，数组
        scope: null //标段范围，经纬度点阵数组
      },
      form: {},
      rules: {
        ecoName: [
          {
            min: 1,
            max: 20,
            required: true,
            message: "请输入1-20位环保垃圾屋名称",
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        bidId: {
          required: true,
          message: "请选择所属标段",
          trigger: "change"
        }
      }
    };
  },

  methods: {
    async getInfo() {
      await _getData(this, this.$route.query.id);
    }
  }
};

async function _getData(self, id) {
  //详情数据
  let formData = await self.$api_hw.greenGarbageHouse_getById({
    id: id //this.$route.query.id
  });

  self.form = _fixData(formData);

  /**
   * 把服务器端的Data 修改一下以方便编辑
   */
  function _fixData(d) {
    //先检查key是否存在，不存在赋值null
    ["ecoCoordinateScope", "bidScope"].forEach(key => {
      if (!d.hasOwnProperty(key)) {
        d[key] = null;
      }
    });

    //然后对key对应的值做处理
    for (let key in d) {
      switch (key) {
        case "electricityReady":
        case "isDrawn":
        case "waterReady":
          d[key] = !!d[key];
          break;
        case "ecoCoordinateScope":
          try {
            self.mapData.lnglat = JSON.parse(d[key]);
          } catch (e) {
            self.mapData.lnglat = null;
          }
          self.log("mapData.lnglat", self.mapData.lnglat);
          break;
        case "bidScope":
          try {
            self.mapData.scope = JSON.parse(d[key]);
          } catch (e) {
            self.mapData.scope = null;
          }
          break;
        case "ecoImageUrls":
          self.uploadData.fileKey = d[key];
          break;
      }
    }
    return d;
  }
}
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%
  /deep/ .el-input--mini
    width: 170px
  .el-date-editor
    width: 170px
  .el-date-editor-bigwidth
    width: 350px
  .el-input-bigwidth
    width: 350px

</style>
<style scoped>
.deliveryTime .el-date-editor--date {
  width: 178px !important;
}
</style>